
    import api from "@/api/index"; //ABP API接口
    import {Component, Ref, Vue} from "vue-property-decorator";
    import RejectDialog from "@/components/RejectDialog/index.vue";

    import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
    import {} from "@/api/appService";
    import {UserModule} from "@/store/modules/user";
    import VueExt from "@/core/core";
    import VueExtension from "@/core/core";
    import AuditTabOfInternalMonthlySummarize from "@/views/myAudit/tabs/tabInternalMonthlySummarize.vue";
    import AuditCount from "@/views/myAudit/components/AuditCount.vue";
    import AuditTabOfExternalDueDiligenceRecord from "@/views/myAudit/tabs/tabExternalDueDiligenceRecord.vue";
    import AuditTabOfExternalMonthlyPlan from "@/views/myAudit/tabs/tabExternalMonthlyPlan.vue";
    import AuditTabOfExternalMonthlySummarize from "@/views/myAudit/tabs/tabExternalMonthlySummarize.vue";
    import AuditTabOfExternalProject from "@/views/myAudit/tabs/tabExternalProject.vue";
    import AuditTabOfExternalProjectCloseReport from "@/views/myAudit/tabs/tabExternalProjectCloseReport.vue";
    import AuditTabOfExternalProjectIntention from "@/views/myAudit/tabs/tabExternalProjectIntention.vue";
    import AuditTabOfExternalProjectProcessMonitoring
        from "@/views/myAudit/tabs/tabExternalProjectProcessMonitoring.vue";
    import AuditTabOfExternalProjectProgressReport from "@/views/myAudit/tabs/tabExternalProjectProgressReport.vue";
    import AuditTabOfExternalProjectSummaryReport from "@/views/myAudit/tabs/tabExternalProjectSummaryReport.vue";
    import AuditTabOfInternalMonthlyPlan from "@/views/myAudit/tabs/tabInternalMonthlyPlan.vue";
    import AuditTabOfInternalProject from "@/views/myAudit/tabs/tabInternalProject.vue";
    import AuditTabOfInternalProjectSummaryReport from "@/views/myAudit/tabs/tabInternalProjectSummaryReport.vue";
    import AuditTabOfBeneficiary from "@/views/myAudit/tabs/tabBeneficiary.vue";
    import AuditTabOfInternalProjectBeneficiary from "@/views/myAudit/tabs/tabInternalProjectBeneficiary.vue";
    import AuditTabOfInternalServiceRecord from "@/views/myAudit/tabs/tabInternalServiceRecordOfBeneficiary.vue";
    import AuditTabOfInternalServiceRecordBySchool from "@/views/myAudit/tabs/tabInternalServiceRecordOfSchool.vue";
    import AuditTabOfInternalServiceRecordByBeneficiary
        from "@/views/myAudit/tabs/tabInternalServiceRecordOfBeneficiary.vue";

    import AuditTabOfFinancePartnerCapitalApply from "@/views/myAudit/tabs/tabFinancePartnerCapitalApply.vue";

    import AuditTabOfFinancePartnerCapitalApplySettlement from "@/views/myAudit/tabs/tabFinancePartnerCapitalApplySettlement.vue";

    import TabInternalPersonalYearEndSummary from "@/views/myAudit/tabs/tabInternalPersonalYearEndSummary.vue";


    @Component({
        name: "AuditTabGroupOfInternalProject",
        components: {
          TabInternalPersonalYearEndSummary,
            AuditTabOfInternalServiceRecordByBeneficiary,
            AuditTabOfInternalServiceRecordBySchool,
            AuditTabOfInternalServiceRecord,
            AuditTabOfInternalProjectBeneficiary,
            AuditTabOfBeneficiary,
            AuditTabOfInternalProjectSummaryReport,
            AuditTabOfInternalProject,
            AuditTabOfInternalMonthlyPlan,
            AuditTabOfInternalMonthlySummarize,
            AuditCount,
            AuditTabOfFinancePartnerCapitalApply,
            AuditTabOfFinancePartnerCapitalApplySettlement
        }
    })
    export default class AuditTabGroupOfInternalProject extends Vue {

        countDic: any = {
            'internal-monthly-summarize': 0,
            'internal-monthly-plan': 0,
            'internal-project': 0,
            'internal-project-summary-report': 0,
            'internal-project-beneficiary': 0,
            'internal-service-record': 0,
            'finance-partner-capital-apply': 0,
            'internal-year-end-summary': 0
        }

        totalCount:number = 0;

        handleCountChange(target: string, $event: number) {
          this.countDic[target] = $event;
          this.totalCount += $event;
          this.$emit('count-changed', ['internal-project', this.totalCount]);
        }

        hasAuditPermission(permissionCode: string) {
            return (this as unknown as VueExtension).hasPermission(permissionCode);
        }

    }
