
  import {Component, Vue} from "vue-property-decorator";
  import AuditTabGroupOfInternalProject from "@/views/myAudit/internalProject.vue";
  import AuditTabGroupOfFinance from "@/views/myAudit/finance.vue";
  import AuditTabGroupOfExternalProject from "@/views/myAudit/externalProject.vue";
  import AuditTabOfOrganizationUnit from "@/views/myAudit/tabs/tabOrganizationUnit.vue";
  import AuditTabGroupOfNormal from "@/views/myAudit/normal.vue";
  import AuditCount from "@/views/myAudit/components/AuditCount.vue";

  @Component({name:'MyAudit',
    components: {
      AuditTabGroupOfNormal,
      AuditTabOfOrganizationUnit,
      AuditTabGroupOfExternalProject,
      AuditTabGroupOfFinance,
      AuditTabGroupOfInternalProject,
      AuditCount}
  })
  export default class MyAudit extends Vue {
    countDic:any = {
      'normal': 0,
      'internal-project': 0,
      'external-project': 0,
      'finance': 0,
    }

    handleCountChange(target:any) {
      this.countDic[target[0]] = target[1];
    }
  }
