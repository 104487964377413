

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {CustomFormType} from "@/api/appService";

  @Component({name:'AuditTabOfInternalProjectBeneficiary',
    components: {AuditTabPane}
  })
  export default class AuditTabOfInternalProjectBeneficiary extends Vue{

    fetchData(){
      return api.projectBeneficiaryMapping.getMyAll;
    }


    async handleAudit(dto:any){

      let formId = '';
      await api.customFormService.getCustomFormId({hostType:CustomFormType.Beneficiary,hostId:dto.project.id}).then(res=>{
        formId = res;
      });

      return this.$router.push({
        name: 'internalProjectProposalBeneficiaryDetail',
        query: {
          id: dto.id,
          formId: String(formId),
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
