

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {AuditUserLogType} from "@/api/appService";

  @Component({name:'AuditTabOfCapitalIncomeRecordFromOther',
    components: {AuditTabPane}
  })
  export default class AuditTabOfCapitalIncomeRecordFromOther extends Vue{

    fetchData(){
      return api.capitalIncomeRecordFromOther.getMyAll;
    }


    handleAudit(dto:any){
      return this.$router.push({
        name: "otherIncomeDetail",
        params: {
          id: dto.id
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
