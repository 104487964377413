

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {AuditUserLogType, CustomFormType} from "@/api/appService";

  @Component({name:'AuditTabOfInternalProject',
    components: {AuditTabPane}
  })
  export default class AuditTabOfInternalProject extends Vue{

    fetchData(){
      return api.internalProject.getMyAll;
    }


    handleAudit(dto:any){
      return this.$router.push({
        name: 'internalProjectProposalDetail',
        query: {
          id: dto.project.id,
          hostType: CustomFormType.InternalProject
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
