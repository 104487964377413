
import { Component, Vue } from "vue-property-decorator";
import {
  AuditFlowDto,
  AuditFlowScope,
  AuditFlowType,
  AuditNodeDto,
  AuditUserLogDto,
  AuditUserLogStatus,
  AuditUserLogType
} from "@/api/appService";
import api from "@/api";
import moment from "moment";

function getLogStatusColor(log: AuditUserLogDto) {
  if (log.status === AuditUserLogStatus.Back) {
    return "#ea7077";
  } else if (log.status === AuditUserLogStatus.Reject) {
    return "#f00";
  } else if (log.status === AuditUserLogStatus.Pass) {
    return "#8ce78c";
  } else if (log.status === AuditUserLogStatus.Continue) {
    return "#3f93b0";
  } else {
    return "#87939a";
  }
}

interface LevelNode {
  level: number;
  nodes: AuditNodeItem[];
}

class AuditNodeItem {
  private readonly _node: AuditNodeDto;
  private readonly _log?: AuditUserLogDto;
  private readonly _logArray?: AuditUserLogDto[];

  constructor(node: AuditNodeDto, allLogs: AuditUserLogDto[]) {
    this._node = node;
    let logs = allLogs
      .filter(s => s.auditNodeId === node.id)
      .sort((s1, s2) => s1.id! - s2.id!);
    this._logArray = logs;
    if (logs && logs.length) {
      this._log = logs[0];
    }
  }

  getTagColor() {
    if (this._log) {
      return getLogStatusColor(this._log);
    } else {
      return "#87939a"; // 灰
    }
  }

  get isAudited() {
    return !!this._log;
  }

  getStatusSummary(auditFlow: AuditFlowDto, levelNode: LevelNode) {
    if (!this._log) {
      if (
        auditFlow.type === AuditFlowType.AuditOne &&
        levelNode.nodes.some(s => s.node.id !== this.node.id && s.isAudited)
      ) {
        return "已有人审核，自动跳过";
      } else {
        return "等待";
      }
    }

    if (this._log.status === AuditUserLogStatus.Continue) {
      return "继续";
    } else if (this._log.status === AuditUserLogStatus.Pass) {
      return "审批通过";
    } else if (this._log.status === AuditUserLogStatus.Reject) {
      return "审批拒绝";
    } else if (this._log.status === AuditUserLogStatus.Back) {
      return "退回";
    }
  }

  get node() {
    return this._node;
  }

  get log() {
    return this._log;
  }

  get logArray() {
    return this._logArray;
  }

  get auditObject() {
    if (this.node.roleId) {
      return `角色:${this.node.roleName}`;
    } else {
      return `指定人:${this.node.userName}`;
    }
  }
}

@Component({
  name: "AuditFlowDetail"
})
export default class AuditFlowDetail extends Vue {
  private hostId?: string;
  private hostType?: AuditFlowScope;
  private auditFlowId?: string;

  private auditFlow?: AuditFlowDto;
  private auditLogs?: AuditUserLogDto[] = [];
  private levelNodes?: LevelNode[] = [];

  private loadForAuditFlow: boolean = true;
  private loadForAuditLog: boolean = true;

  async created() {
    this.hostId = this.$route.params.hostId;
    this.hostType = this.$route.params.hostType as AuditFlowScope;
    this.auditFlowId = this.$route.params.auditFlowId;
    if(!this.auditFlowId){
      return
    }
    let getLogPromise;
    switch (this.hostType) {
      //  外部项目意向书管理
      case AuditFlowScope.ExternalProjectIntention:
        getLogPromise = api.externalProjectIntention.getLogs;
        break;

      //  外部项目尽调报告
      case AuditFlowScope.DueDiligenceRecord:
        getLogPromise = api.externalDueDiligenceRecord.getLogs;
        break;

      //  外部项目计划书管理
      case AuditFlowScope.ExternalProject:
        getLogPromise = api.externalProject.getLogs;
        break;

      //  外部项目-月计划
      case AuditFlowScope.ExternalMonthlyPlan:
        getLogPromise = api.externalMonthlyPlanService.getLogs;
        break;

      //  外部项目-月总结
      case AuditFlowScope.ExternalMonthlySummarize:
        getLogPromise = api.externalMonthlySummarize.getLogs;
        break;

      //  外部项目-监测记录
      case AuditFlowScope.ExternalProjectProcessMonitoring:
        getLogPromise = api.externalProjectProcessMonitoringService.getLogs;
        break;

      //  外部项目-中期报告
      case AuditFlowScope.ExternalProjectProgressReport:
        getLogPromise = api.externalProjectProcessReport.getLogs;
        break;

      //  外部项目-项目自评报告管理
      case AuditFlowScope.ExternalProjectSummaryReport:
        getLogPromise = api.externalProjectSummaryReport.getLogs;
        break;

      //  外部项目-结项评估报告管理
      case AuditFlowScope.ExternalProjectCloseReport:
        getLogPromise = api.externalProjectCloseReport.getLogs;
        break;

      //  外部项目-合作方资金申请管理
      case AuditFlowScope.PartnerCapitalApply:
        getLogPromise = api.partnerCapitalApply.getLogs;
        break;

      //  内部项目意向书管理
      case AuditFlowScope.InternalProject:
        getLogPromise = api.internalProject.getLogs;
        break;

      //  内部项目月总结管理
      case AuditFlowScope.InternalMonthlySummarize:
        getLogPromise = api.internalMonthlySummarize.getLogs;
        break;

      //  受益人管理
      case AuditFlowScope.Beneficiary:
        getLogPromise = api.beneficiary.getLogs;

      // 受益人服务记录
      case AuditFlowScope.ServiceRecord:
        getLogPromise = api.beneficiaryServiceRecord.getLogs;
        break;

      // 项目受益人对应关系
      case AuditFlowScope.ProjectBebeficiaryMapping:
        getLogPromise = api.projectBeneficiaryMapping.getLogs;
        break;

      //内部项目总结报告
      case AuditFlowScope.InternalProjectSummaryReport:
        getLogPromise = api.internalProjectSummaryReports.getLogs;
        break;

      //内部项目月计划报告
      case AuditFlowScope.InternalMonthlyPlan:
        getLogPromise = api.internalMonthlyPlan.getLogs;
        break;

      //合作方资金申请
      case AuditFlowScope.PartnerCapitalApply:
        getLogPromise = api.partnerCapitalApply.getLogs;
        break;

      //支出记录单
      case AuditFlowScope.CapitalExpenseRecord:
        getLogPromise = api.capitalExpenseRecord.getLogs;
        break;

      //其他资金收入
      case AuditFlowScope.CapitalIncomeRecordFromOther:
        getLogPromise = api.capitalIncomeRecordFromOther.getLogs;
        break;

      //资金捐赠
      case AuditFlowScope.CapitalIncomeRecordFromDonation:
        getLogPromise = api.capitalIncomeRecordFromDonation.getLogs;
        break;

      //个人年终总结
      case AuditFlowScope.InternalPersonalYearEndSummary:
        getLogPromise = api.internalPersonalYearEndSummary.getLogs;
        break;

      //机构审核
      case AuditFlowScope.PartnerApply:
        getLogPromise = api.organizationUnit.getLogs;
        break;

      //外部项目变更申请
      case AuditFlowScope.ExternalProjectAdjustment:
        getLogPromise = api.externalProjectChangeApply.getLogs;
        break;

      //合作方资金申请结算
      case AuditFlowScope.PartnerCapitalApplySettlement:
        getLogPromise = api.partnerCapitalApplySettlement.getLogs;
        break;

      //基金会事项审批
      case AuditFlowScope.MatterAudit:
        getLogPromise = api.matterAuditForm.getLogs;
        break;

      //私车公用
      case AuditFlowScope.PrivateCarPublicSubsidy:
        getLogPromise = api.privateCarPublicSubsidyForm.getLogs;
        break;

      //用车申请
      case AuditFlowScope.VehicleUsageApplication:
        getLogPromise = api.vehicleUsageApplication.getLogs;
        break;

      //出差申请
      case AuditFlowScope.TravelApproval:
        getLogPromise = api.travelApproval.getLogs;
        break;

      //物品借用
      case AuditFlowScope.ItemBorrowing:
        getLogPromise = api.itemBorrowing.getLogs;
        break;

      //物资入库
      case AuditFlowScope.InventoryIn:
        getLogPromise = api.inventoryIn.getLogs;
        break;

      //物资出库
      case AuditFlowScope.InventoryOut:
        getLogPromise = api.inventoryOut.getLogs;
        break;

      default:
        this.$message({ message: "不支持的审批流程类型", type: "error" });
        return;
    }

    // @ts-ignore
    await getLogPromise({ id: Number(this.hostId) }).then(res => {
      this.auditLogs = res;
      this.loadForAuditLog = false;
    });

    await api.auditFlow.get({ id: this.auditFlowId }).then(res => {
      this.auditFlow = res;

      const levelNodes: LevelNode[] = [];
      let auditNodeItems = this.auditFlow.auditNodes?.map(
        node => new AuditNodeItem(node, this.auditLogs!)
      );

      auditNodeItems?.forEach(item => {
        let filterResult = levelNodes.filter(s => s.level === item.node.index);
        let levelNodeItem: LevelNode;
        if (filterResult.length === 0) {
          levelNodeItem = { level: item.node.index!, nodes: [] };
          levelNodes.push(levelNodeItem);
        } else {
          levelNodeItem = filterResult[0];
        }

        levelNodeItem.nodes.push(item);
      });

      this.levelNodes = levelNodes.sort((a1, a2) => a1.level - a2.level);

      this.loadForAuditFlow = false;
    });
  }

  formatDate(timestamp: string) {
    if (timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "";
    }
  }

  get loading() {
    if(!this.auditFlowId){
      return false
    }
    return this.loadForAuditFlow || this.loadForAuditLog;
  }

  getTimeItemColor(log: AuditUserLogDto) {
    return getLogStatusColor(log);
  }

  get isNeedAllPass() {
    return this.auditFlow?.type === AuditFlowType.AuditAll;
  }

  getStatus(
    nodeId: string,
    auditFlow: AuditFlowDto,
    levelNode: LevelNode,
    log: AuditUserLogDto
  ) {
    if (!log) {
      if (
        auditFlow.type === AuditFlowType.AuditOne &&
        levelNode.nodes.some(s => s.node.id !== nodeId && s.isAudited)
      ) {
        return "已有人审核，自动跳过";
      } else {
        return "等待";
      }
    }

    if (log.status === AuditUserLogStatus.Continue) {
      return "继续";
    } else if (log.status === AuditUserLogStatus.Pass) {
      return "审批通过";
    } else if (log.status === AuditUserLogStatus.Reject) {
      return "审批拒绝";
    } else if (log.status === AuditUserLogStatus.Back) {
      return "退回";
    }
  }
  // 返回
  private cancel() {
    this.$router.back();
  }
}
