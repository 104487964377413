

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {CustomFormType} from "@/api/appService";

  @Component({name:'AuditTabOfOrganizationUnit',
    components: {AuditTabPane}
  })
  export default class AuditTabOfOrganizationUnit extends Vue{
    queryForm = {
      isApply: true
    }
    private formId:string='';

    // created(){
    //   api.customFormService.getCustomFormId({hostId:'',hostType:CustomFormType.OrganizationUnit}).then(res=>{
    //     this.formId = res;
    //   })
    // }

    fetchData(){
      return api.organizationUnit.getMyAll
    }

    handleAudit(dto:any){
      api.customFormService.getCustomFormId({hostId:dto.organizationType,hostType:CustomFormType.OrganizationUnit}).then(res=>{
        this.formId = res;

        return this.$router.push({
          name: "orgDetail",
          query: {
            id: dto.id!.toString(),
            formId: this.formId,
          }
        })
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
