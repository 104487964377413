

  import {Component, Vue,Prop} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {AuditUserLogType} from "@/api/appService";

  @Component({name:'AuditTabOfFinancePartnerCapitalApplySettlement',
    components: {AuditTabPane}
  })
  export default class AuditTabOfFinancePartnerCapitalApplySettlement extends Vue{

    fetchData(){
      return api.partnerCapitalApplySettlement.getMyAll;
    }
      @Prop({required:true,default:false})
      isInternal!:boolean

    handleAudit(dto:any){
      return this.$router.push({
        name: "partnerCapitalApplySettlementDetail",
        params: { id: dto.id!.toString() }
      })
    }

      get queryParams(){
          return {
              isInternal:this.isInternal
          }
      }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
