

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {CustomFormType} from "@/api/appService";

  @Component({name:'AuditTabOfInternalServiceRecordByBeneficiary',
    components: {AuditTabPane}
  })
  export default class AuditTabOfInternalServiceRecordByBeneficiary extends Vue{

    fetchData(){
      return api.beneficiaryServiceRecord.getMyAll;
    }

    get queryParams(){
      return {
        serviceRecordType:'Beneficiary'
      }
    }


    async handleAudit(dto:any){

      let formId = ''
      await api.customFormService
        .getCustomFormId({
          hostType:CustomFormType.BeneficiaryServiceRecord,
          hostId:dto.project.id.toString()
        }).then(res=>{
        formId = res;
      })


      return this.$router.push({
        name: 'internalProjectSchoolServiceRecordDetail',
        query: {
          id: dto.id,
          formId: formId,
          type: 'Beneficiary'
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
