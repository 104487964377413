

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {AuditUserLogType} from "@/api/appService";

  @Component({name:'AuditTabOfFinanceCapitalExpenseRecord',
    components: {AuditTabPane},

    filters: {
      formatDate(year: any, month: any) {
        if (month < 10) {
          month = "0" + month;
        }
        return year + "-" + month;
      },
      formatePayType(type: any, list: any[]) {
        let result = "";
        if (list && list.length > 0) {
          list.map(item => {
            if (item.value == type) {
              result = item.label;
              return;
            }
          });
        }
        return result;
      }
    }
  })
  export default class AuditTabOfFinanceCapitalExpenseRecord extends Vue{

    payTypeList = [
      {
        value: "Cash",
        label: "现金"
      },
      {
        value: "Transfer",
        label: "转账"
      }
    ];
    fetchData(){
      return api.capitalExpenseRecord.getMyAll;
    }


    handleAudit(dto:any){
      return this.$router.push({
        name: "expenseRecordDetail",
        params: {
          id: dto.id
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

  }
