
import {Component, Vue, Ref} from "vue-property-decorator";
import api from "@/api";
import {
  AuditFlowCreateOrEditDto,
  AuditNodeCreateOrEditDto,
  AuditFlowScope,
  RoleDto, AuditFlowType
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import {flatenNodes, INodesBlock, createNodes} from "@/utils/tree";
import draggable from "vuedraggable";

interface ScopeMapping {
  name: AuditFlowScope;
  label: string;
}

@Component({
  components: {
    draggable
  }
})
export default class EditAuditFlow extends Vue {
  @Ref() readonly dataForm!: ElForm;

  roles: RoleDto[] = [];
  allScopes: ScopeMapping[] = [];

  async created() {
    this.fetchAllScopes()

    const p1 = api.role.getAll({
      maxResultCount: 100
    });
    const p2 = api.auditNodePreDefine.getAll();

    const p3 = api.user.getAllUsers();

    await Promise.all([p1, p2, p3]).then(values => {
      this.roles = values[0].items!;
      this.predefined = values[1].items!.map(x =>
        Object.assign({}, x, {id: api.guid})
      );
      this.users = values[2]!.map(x => {
        return {label: x.name, value: x.id};
      });

      console.log(this.predefined);
    });

    this.fetchReadyData();
  }

  users: any[] = [{label: "admin", value: 2}];

  fetchAllScopes() {
    api.enumService.getValues({typeName: 'AuditFlowScope'}).then((res: any) => {
      this.allScopes = res
    });
  }

  getAuditFlowType(value: string) {
    if (value === 'AuditAll') {
      return AuditFlowType.AuditAll
    } else if (value === 'AuditOne') {
      return AuditFlowType.AuditOne
    }
  }

  fetchReadyData() {
    const id = this.$route.params.id;
    api.auditFlow.getForEdit({id: id ? id : api.guid}).then(res => {
      this.form = res.data;
      this.rows = createNodes(res.data!.auditNodes!);
    });
  }

  // 移除空行
  private removeEmptyRow() {
    this.rows = this.rows.filter(x => x.items.length > 0);
  }

  // 添加一行
  private addRow(r: number) {
    let index = this.rows.length;
    let items =
      r > 0
        ? [
          {
            desc: `审核节点${index + 1}`,
            userId: undefined,
            userName: undefined,
            roleId: undefined,
            roleName: undefined,
            auditFlowId: undefined,
            tenantId: undefined
          }
        ]
        : [];
    this.rows = [
      ...this.rows,
      {
        items: items
      }
    ];
  }

  drag = false;

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: "ghost"
    };
  }

  // 删除整行
  private deleteRow(index: number) {
    this.rows.splice(index, 1);
  }

  // 删除空节点
  private removeEmptyNode(index: number) {
    const result: INodesBlock[] = [];

    this.rows.forEach(r => {
      result.push({
        items: r.items.filter(x => {
          return !!x.userId || !!x.roleId;
        })
      });
    });
    this.rows = result;
    this.removeEmptyRow();
  }

  // 选权限下拉结束
  private selectRole(value: number, item: AuditNodeCreateOrEditDto) {
    if (!value) {
      item.roleName = undefined;
      item.roleId = undefined;
    } else {
      item.roleName = this.roles.filter(x => x.id === value)[0].displayName;
      item.roleId = this.roles.filter(x => x.id === value)[0].id;
    }
  }

  // 选验证用户下拉结束
  private selectUser(value: number, item: AuditNodeCreateOrEditDto) {
    console.log(value, item);
    if (!value) {
      item.userName = undefined;
      item.userId = undefined;
    } else {
      item.userName = this.users.filter(x => x.value === value)[0].label;
      item.userId = this.users.filter(x => x.value === value)[0].value;
    }
  }

  // 块Drag结束
  private async dragEnd1(e: any) {
    console.log(e);
  }

  // Node Drag 结束
  private async dragEnd2(e: any) {
  }

  // predefined Drag 结束
  private async dragEnd3(e: any) {
    console.log(this.rows);
  }

  private async dragStart3(e: any) {
    console.log(e);
  }

  recycle: any[] = [];

  private async dragEnd4(e: any) {
    this.recycle = [];
  }

  predefined: AuditNodeCreateOrEditDto[] = [];

  enabled = true;
  rows: INodesBlock[] = [
    {
      items: [
        {
          desc: "审核节点1",
          roleName: undefined,
          roleId: undefined,
          userName: undefined,
          userId: undefined
        }
      ]
    }
  ];

  // 点击保存
  async save() {
    console.log(this.form);
    this.form!.auditNodes = flatenNodes(this.rows);
    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id !== api.guid) {
          await api.auditFlow.update({body: this.form}).then(() => {
            this.$message.success("更新成功");
          });
        } else {
          await api.auditFlow.create({body: this.form}).then(() => {
            this.$message.success("新建成功");
          });
        }
        await this.$router.push({name: "auditFlowDefinitionManagement"});
      }
    });
  }

  // 表单默认值
  defaultData: AuditFlowCreateOrEditDto = {
    title: undefined,
    status: undefined,
    desc: undefined,
    type: undefined,
    auditNodes: undefined,
    id: undefined,
    scope: undefined
  };

  form: AuditFlowCreateOrEditDto | undefined = {...this.defaultData};

  cancel() {
    this.$router.back();
  }

  // 验证开始
  private validNodes = (a: any, b: any, c: Function) => {
    console.log(a, b, c);
    return c(new Error("sdf"));
    // return c();
  };

  roleRule = {
    title: [
      {
        required: true,
        message: "必填",
        trigger: "blur"
      }
    ],
    auditNodes: [
      {
        validate: this.validNodes,
        trigger: "blur"
      }
    ],
    scope: [
      {
        required: true,
        trigger: "blur"
      }
    ]
  };
}
