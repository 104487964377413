

  import {Component, Vue} from "vue-property-decorator";
  import AuditTabPane from "../components/AuditTabPane.vue";
  import api from '@/api';
  import {AuditUserLogType, DataDictionaryDto} from "@/api/appService";

  @Component({name:'tabExternalProjectProposalChangeApply',
    components: {AuditTabPane}
  })
  export default class tabExternalProjectProposalChangeApply extends Vue{

    changeApplyTypeList: DataDictionaryDto[] = [];

    created() {
      //
      api.dataDictionary
        .getDataDictionaryListByKey({ key: "ProjectChangeType" })
        .then(res => {
          this.changeApplyTypeList = res.items!;
        });
    }

    fetchData(){
      return api.externalProjectChangeApply.getMyAll;
    }


    handleAudit(dto:any){
      return this.$router.push({
        name: 'externalProjectChangeApplyDetail',
        params: {
          id: dto.id
        }
      })
    }

    handleCountChange(value:number){
      this.$emit('count-changed',value);
    }

    formatChangeType(str: string) {
      const displayNames: string[] = [];
      this.changeApplyTypeList!.map((item: any) => {
        if (str.indexOf(item.key) > -1) {
          displayNames.push(item.displayName);
        }
      });
      return displayNames.join(",");
    }

  }
